import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/icons' // icon
import 'swiper/css';
Vue.config.productionTip = false;

Vue.use(Element)

Vue.prototype.$typeValue = 'sz';
// Vue.prototype.$typeValue = 'sz';

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
