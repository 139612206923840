<template>
  <div class="home">
    <NavBar></NavBar>
    <router-view></router-view>
    <foot-bar></foot-bar>
  </div>
</template>

<script>
import NavBar from "@/views/NavBar";
import footBar from "@/views/FootBar";

export default {
  name: "index",
  components: { NavBar, footBar },

  mounted() {

    let width = document.documentElement.clientWidth;
  
    if (width < 1000){
      this.$router.push('/phone')
      
    }
  }
};
</script>

<style scoped lang="less">
@import "@/style/index.less";
</style>
