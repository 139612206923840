<template>
    <div class="footBar">
        <p>
            <span>联系地址：安徽省合肥市滨湖卓越城</span>
            <span>联系电话：17764418824</span>
            <span>邮箱：dw588@vip.qq.com</span>
        </p>
        <p>Copyrights © 2024 by 安徽元梦科技有限公司版权所有 皖ICP备2024049068号-1  All Rights Reserved</p>
    </div>
</template>

<script>
export default {
  name: "FootBar"
};
</script>

<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
}

.footBar {
  width: 100%;
  height: 110px;
  background-color: #4a4e59;
  padding-top: 24px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;

  p {
    margin: 0;
    padding: 0;
    line-height: 36px;
    color: #ffffff;
    font-size: 14px;

    span{
      margin: 0 20px;
    }
  }
}
</style>
