<template>
  <div class="menu-box"
       ref="menuBox"
       @mouseout="menuOut"
       @mousemove="menuMove">
    <el-menu
      :default-active="activeIndex"
      class="el-menu"
      mode="horizontal"
      active-text-color="#208fef"
      @select="handleSelect"
      router

    >
      <el-menu-item index="/phone/home">
<!--        <img class="logo-img logo-img1" src="@/assets/img/logo-dg.png" alt="">-->
<!--        <img class="logo-img logo-img2" src="@/assets/img/logo1.png" alt="">-->
<img class="logo-img logo-img2" src="../assets/img/yuanmenglogo.png" alt="">
      </el-menu-item>
      <el-menu-item index="/phone/home">首页</el-menu-item>
      <el-submenu index="2" popper-class="open-menu">
        <template slot="title">产品中心</template>
        <el-menu-item index="/phone/number">数字孪生</el-menu-item>
        <el-menu-item index="/phone/operate">智慧运营</el-menu-item>
        <el-menu-item index="/phone/study">智慧学习</el-menu-item>
      </el-submenu>
      <el-submenu index="3" popper-class="open-menu">
        <template slot="title">解决方案</template>
        <el-menu-item index="/phone/city">智慧城市</el-menu-item>
        <el-menu-item index="/phone/campus">智慧校园</el-menu-item>
        <el-menu-item index="/phone/park">智慧园区</el-menu-item>
        <el-menu-item index="/phone/hospital">智慧医院</el-menu-item>
        <el-menu-item index="/phone/factory">智慧工厂</el-menu-item>
        <el-menu-item index="/phone/airport">智慧机场</el-menu-item>
      </el-submenu>
      <!--      <el-menu-item index="/news">新闻动态</el-menu-item>-->
      <el-menu-item index="/phone/aboutme">关于我们</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      activeIndex: "",
      scrollTop: 0
    };
  },
  watch: {
    $route: function(to, form) {
      sessionStorage.setItem("keyPath", to.path);
      this.activeIndex = to.path;
      let scrollTop = document.documentElement.scrollTop;
      let menuBoxDom = this.$refs.menuBox;
      if (to.path == "/home" && scrollTop == "0") {
        menuBoxDom.className = "menu-box transparent-menu";
      } else {
        menuBoxDom.className = "menu-box";
      }
    }
  },
  methods: {
    handleSelect(key, keyPath) {

    },
    setClass() {
      let path = this.$route.path;
      let scrollTop = document.documentElement.scrollTop;
      this.scrollTop = scrollTop;
      let menuBoxDom = this.$refs.menuBox;
      if (path == "/home" && scrollTop == "0") {
        menuBoxDom.className = "menu-box transparent-menu";
      } else {
        menuBoxDom.className = "menu-box";
      }
    },
    //监听滚动
    handleScroll() {
      this.setClass();
    },
    //监听鼠标移入
    menuMove() {
      // if (this.scrollTop == 0) {
      //   if (this.$refs.menuBox.className.indexOf("transparent-menu") != -1) {
      //     this.$refs.menuBox.classList.remove("transparent-menu");
      //   }
      // }
    },
    //监听鼠标移出
    menuOut() {
      // this.setClass();
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.setClass();
    this.activeIndex = sessionStorage.getItem("keyPath") || "home";
  }
};
</script>

<style scoped lang="less">
@import "@/style/index.less";

.menu-box {
  width: 1920px;
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -960px;
  height: 58px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  z-index: 999;
  transition: all 0.5s;

  .el-menu {
    width: 1300px;
    border-bottom: none;
    background: transparent;

    .el-menu-item {
      color: #494949;
      font-weight: bold;
      font-size: 16px;

      &.is-active {
        border-bottom: none;
      }
    }

    :deep(.el-submenu__title) {
      color: #494949;
      font-weight: bold;
      font-size: 16px;

      &:hover {
        background-color: transparent !important;
      }
    }

    .el-menu-item:focus, .el-menu-item:hover {
      background-color: transparent;
      color: rgb(32, 143, 239);
    }

    :deep(.el-menu--horizontal) {
      background-color: transparent;

      .el-menu {
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 10px;
        min-width: 120px;
        text-align: center;

        .el-menu-item {
          background-color: transparent !important;
          min-width: 120px;
          font-size: 14px;
          font-weight: normal;


          &:hover {
            background-color: rgba(255, 255, 255, 0.7) !important;
            color: rgb(32, 143, 239) !important;
          }
        }
      }
    }
  }

  .logo-img {
    height: 100%;
  }

  .logo-img1 {
    display: inline-block;
  }

  .logo-img2 {
    display: inline-block;
  }

  &.transparent-menu {
    background-color: rgba(0, 0, 0, 0.5);

    //.logo-img1 {
    //  display: none;
    //}
    //
    //.logo-img2 {
    //  display: inline-block;
    //}


    .el-menu-item {
      color: #ffffff;

      &:hover {
        background-color: transparent !important;
        color: rgb(32, 143, 239);
      }
    }

    .el-menu {
      :deep(.el-submenu__title) {
        color: #ffffff;
        font-weight: bold;
        font-size: 16px;

        i {
          color: #ffffff;
        }
      }
    }

    :deep(.el-menu--horizontal) {
      background-color: transparent;

      .el-menu {
        //background-color: rgba(255,255,255,0.6);
        //background-color: rgba(65, 66, 78, 0.6);
        background-color: #3e4251;
        border-radius: 10px;
        min-width: 120px;
        text-align: center;

        .el-menu-item {
          background-color: transparent !important;
          color: #ffffff !important;
          min-width: 120px;
          font-size: 14px;
          font-weight: normal;

          &:hover {
            background-color: rgba(65, 66, 78, 0.5) !important;
            color: rgb(32, 143, 239) !important;
          }
        }
      }
    }
  }
}


</style>
