<template>
  <div class="home" id="home">
    <div class="container">
      <NavBar></NavBar>
      <router-view></router-view>
      <foot-bar></foot-bar>
    </div>
  </div>
</template>

<script>
import NavBar from "@/phone/NavBar";
import footBar from "@/phone/FootBar";

export default {
  name: "index",
  components: { NavBar, footBar },
  data() {
    return {};
  },
  watch: {},
  mounted() {
    let width = document.documentElement.clientWidth;
    if (width >= 1000) {
      this.$router.push("/");
    }
    document.body.style.overflowY =
      "scroll"; /* 在 mounted 钩子函数中将 body 的溢出设置为滚动 */

    this.handleScreenAuto();
    window.onresize = () => this.handleScreenAuto();
  },
  methods: {
    handleScreenAuto() {
      const designDraftWidth = 1920; //设计稿的宽度

      // 根据屏幕的变化适配的比例
      const scale = document.documentElement.clientWidth / designDraftWidth;

      // 缩放比例
      document.querySelector(
        ".container"
      ).style.transform = `scale(${scale}) translateX(-50%)`;
    },
  },
};
</script>

<style scoped lang="less">
@import "@/style/index.less";

#home {
  width: 100vw;
  // transform-origin: left top;
  // position: absolute;
  // left: 0;
  // top: 0;
  .container {
    display: inline-block;
    width: 1920px; //设计稿的宽度
    transform-origin: 0 0;
    position: absolute;
    left: 50%;
  }
}
</style>