import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/index.vue";
import PhoneIndex from "../phone/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Index,
    redirect: '/home',
    children:[
      {
        path: '/home',
        component: () => import('@/views/Home.vue'),
        name: 'Home',
      },
      //产品中心
      {
        path: '/operate',
        component: () => import('@/views/product/operate.vue'),
        name: 'Operate',
      }, {
        path: '/study',
        component: () => import('@/views/product/study.vue'),
        name: 'Study',
      },{
        path: '/number',
        component: () => import('@/views/product/number.vue'),
        name: 'Number',
      },
      //解决方案
      {
        path: '/city',
        component: () => import('@/views/solution/city.vue'),
        name: 'City',
      },
      {
        path: '/airport',
        component: () => import('@/views/solution/airport.vue'),
        name: 'Airport',
      },
      {
        path: '/campus',
        component: () => import('@/views/solution/campus.vue'),
        name: 'Campus',
      },
      {
        path: '/factory',
        component: () => import('@/views/solution/factory.vue'),
        name: 'Factory',
      },
      {
        path: '/hospital',
        component: () => import('@/views/solution/hospital.vue'),
        name: 'Hospital',
      },
      {
        path: '/park',
        component: () => import('@/views/solution/park.vue'),
        name: 'Park',
      },
      //新闻动态
      {
        path: '/news',
        component: () => import('@/views/news/news.vue'),
        name: 'News',
      },
      //关于我们
      {
        path: '/aboutme',
        component: () => import('@/views/about/aboutme.vue'),
        name: 'Aboutme',
      },
      {
        path: '/city',
        component: () => import('@/views/solution/city.vue'),
        name: 'Aboutme',
      },
    ]
  },
  {
    path: "/phone",
    name: "home",
    component: PhoneIndex,
    redirect: '/phone/home',
    children:[
      {
        path: '/phone/home',
        component: () => import('@/phone/Home.vue'),
        name: 'PhoneHome',
      },
      //产品中心
      {
        path: '/phone/operate',
        component: () => import('@/phone/product/operate.vue'),
        name: 'PhoneOperate',
      }, {
        path: '/phone/study',
        component: () => import('@/phone/product/study.vue'),
        name: 'PhoneStudy',
      },{
        path: '/phone/number',
        component: () => import('@/phone/product/number.vue'),
        name: 'PhoneNumber',
      },
      //解决方案
      {
        path: '/phone/city',
        component: () => import('@/phone/solution/city.vue'),
        name: 'PhoneCity',
      },
      {
        path: '/phone/airport',
        component: () => import('@/phone/solution/airport.vue'),
        name: 'PhoneAirport',
      },
      {
        path: '/phone/campus',
        component: () => import('@/phone/solution/campus.vue'),
        name: 'PhoneCampus',
      },
      {
        path: '/phone/factory',
        component: () => import('@/phone/solution/factory.vue'),
        name: 'PhoneFactory',
      },
      {
        path: '/phone/hospital',
        component: () => import('@/phone/solution/hospital.vue'),
        name: 'PhoneHospital',
      },
      {
        path: '/phone/park',
        component: () => import('@/phone/solution/park.vue'),
        name: 'PhonePark',
      },
      //新闻动态
      {
        path: '/phone/news',
        component: () => import('@/phone/news/news.vue'),
        name: 'PhoneNews',
      },
      //关于我们
      {
        path: '/phone/aboutme',
        component: () => import('@/phone/about/aboutme.vue'),
        name: 'PhoneAboutme',
      },
      {
        path: '/phone/city',
        component: () => import('@/phone/solution/city.vue'),
        name: 'PhoneAboutme',
      },
    ]
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})

export default router;
